.units-dropdown .syt-antd-select-disabled .syt-antd-select-selector {
  color: #718096 !important;
  background: #F3F4F6 !important;
  cursor: not-allowed;
}

.units-dropdown .syt-antd-select-selector {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.units-quantity .gr-form-input {
  position:relative;
  left: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-width: 12rem;
}

.units-quantity .gr-form-input:disabled {
  background: #F3F4F6 !important;
  cursor: not-allowed;
}

.units-dropdown .syt-antd-select-selector .syt-antd-select-selection-search input:focus {
  border: 0 !important;
  outline-offset: 0 !important;
  --tw-ring-color: none;
}

.units-dropdown .syt-antd-select-focused .syt-antd-select-selector {
  border: 0 !important;
  outline-offset: 0 !important;
  box-shadow: none !important;

}
